import exportOptions from '@common/json/exportOptions';

export default {
  ...exportOptions,
  poNumber: 'PO Number',
  vendorNumber: 'Vendor Number',
  orderTotalPayment: 'Total Payment',
  createdOn: 'Booking Creation Date',
  depotLocationName: 'Wob Inbound Facility',
  shippingServiceProvider: 'Collection Service',
  createdBy: 'Created By',
  status: 'Status',
  requestedCollectionDate: 'Expected collection date',
  packageName: 'Package name',
  packageCount: 'Package count',
  pricesIncludeTax: 'Prices include tax',
  estimatedDiscountAmount: 'Estimated discount amount',
  estimatedAmountExcludingTax: 'Estimated amount excluding tax',
  estimatedAmountIncludingTax: 'Estimated amount including tax',
  paymentStatus: 'Payment Status',
  totalNetWeight: 'Total Net Weight',
  totalWeight: 'Total weight',
  netWeight: 'Net weight',
  sealNumber: 'Seal Number',
  collectedOn: 'Collected on',
  blackToteActualDropOffQuantity: 'Black Tote Actual Drop off Quantity',
  blackToteActualPickUpNetWeight: 'Black Tote Actual Pick up Net Weight',
  blackToteActualPickUpQuantity: 'Black Tote Actual Pick up Quantity',
  blackToteActualPickUpWeight: 'Black Tote Actual Pick up Weight',
  blackToteRequestedDropOffQuantity: 'Black Tote Requested Drop off Quantity',
  blackToteRequestedPickUpQuantity: 'Black Tote Requested Pick up Quantity',
  cardboardBoxActualDropOffQuantity: 'Cardboard Box Actual Drop off Quantity',
  cardboardBoxActualPickUpNetWeight: 'Cardboard Box Actual Pick up Net Weight',
  cardboardBoxActualPickUpQuantity: 'Cardboard Box Actual Pick up Quantity',
  cardboardBoxActualPickUpWeight: 'Cardboard Box Actual Pick up Weight',
  cardboardBoxRequestedDropOffQuantity: 'Cardboard Box Requested Drop off Quantity',
  cardboardBoxRequestedPickUpQuantity: 'Cardboard Box Requested Pick up Quantity',
  magnumActualDropOffQuantity: 'Magnum Actual Drop off Quantity',
  magnumActualPickUpNetWeight: 'Magnum Actual Pick up Net Weight',
  magnumActualPickUpQuantity: 'Magnum Actual Pick up Quantity',
  magnumActualPickUpWeight: 'Magnum Actual Pick up Weight',
  magnumRequestedDropOffQuantity: 'Magnum Requested Drop off Quantity',
  magnumRequestedPickUpQuantity: 'Magnum Requested Pick up Quantity',
  redToteActualDropOffQuantity: 'Red Tote Actual Drop off Quantity',
  redToteActualPickUpNetWeight: 'Red Tote Actual Pick up Net Weight',
  redToteActualPickUpQuantity: 'Red Tote Actual Pick up Quantity',
  redToteActualPickUpWeight: 'Red Tote Actual Pick up Weight',
  redToteRequestedDropOffQuantity: 'Red Tote Requested Drop off Quantity',
  redToteRequestedPickUpQuantity: 'Red Tote Requested Pick up Quantity',
  sackActualDropOffQuantity: 'Sack Actual Drop off Quantity',
  sackActualPickUpNetWeight: 'Sack Actual Pick up Net Weight',
  sackActualPickUpQuantity: 'Sack Actual Pick up Quantity',
  sackActualPickUpWeight: 'Sack Actual Pick up Weight',
  sackRequestedDropOffQuantity: 'Sack Requested Drop off Quantity',
  sackRequestedPickUpQuantity: 'Sack Requested Pick up Quantity',
  yellowToteActualDropOffQuantity: 'Yellow Tote Actual Drop off Quantity',
  yellowToteActualPickUpNetWeight: 'Yellow Tote Actual Pick up Net Weight',
  yellowToteActualPickUpQuantity: 'Yellow Tote Actual Pick up Quantity',
  yellowToteActualPickUpWeight: 'Yellow Tote Actual Pick up Weight',
  yellowToteRequestedDropOffQuantity: 'Yellow Tote Requested Drop off Quantity',
  yellowToteRequestedPickUpQuantity: 'Yellow Tote Requested Pick up Quantity',
  requestNewCollection: 'Make Request',
  emptyTitle: 'No requested collections yet',
  emptyDescription: 'You don\'t have any requested collections.',
  emptyLineDescription: 'You don\'t have any no collections line items.',
  noResultDescription: 'Sorry, but there are no completed collections with the given filter options.',
  noResultLineDescription: 'Sorry, but there are no collections line items with the given filter options.',
  noResultDescription2: 'Try to redefine the filtering criteria.',
  exportSelectedConfirm: 'Do you want to export all selected rows?',
  exportFilteredConfirm: 'Do you want to export all data result?',
  exportConfirm: 'Do you want to export all data?',
  modify: 'Modify',
  cancel: 'Cancel',
  close: 'Close',
  void: 'Void',
  markAsFailed: 'Mark as Failed',
  setStatus: 'Set Payment Status',
  exportBookingFileName: 'requested-collections-export',
  exportLineItemsFileName: 'collection-line-items-export',
  exportFailed: 'Export request failed.',
  packageLoadFailed: 'Package Type load failed.',
  saveSuccess: 'Collection successfully created.',
  saveFailed: 'Collection create failed.',
};
