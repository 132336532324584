import {Injectable} from '@angular/core';
import {CollectionsInterface} from '@common/components/collections/collections.model';
import {GridRequest} from '@common/globals/gridRequest';
import {GridResponse} from '@common/globals/gridResponse';
import {setResponseMessage} from '@common/statics/setResponseMessage';
import {CollectionCloseRequest, MarkAsFailedRequest} from '@common/ts/backofficeFormInterfaces';
import {
  BookingDayDTO,
  CollectionBooking,
  CollectionHistoryDTO,
  CollectionLineDTO,
  CollectionRequestDTO,
  ExportTypes,
  ScheduledCallHistoryDTO,
} from '@common/ts/collectionInterfaces';
import {BACKEND_URL} from '@common/ts/config';
import {ItemLocation, ItemLocationOfCompany, ItemLocationOfVendorNumber, PackageTypes, PossibleValuesDTO} from '@common/ts/interfaces';
import {map, Observable} from 'rxjs';

import texts from './collections';

@Injectable()
export class CollectionsService extends CollectionsInterface {

  private serviceUrl = `${BACKEND_URL}/backoffice/v1/collection`;
  private callServiceUrl = `${BACKEND_URL}/collection/v1/scheduledCall`;
  private reportingUrl = `${this.serviceUrl}/reporting`;

  override getCompanies(isReport = false): Observable<PossibleValuesDTO<string>[]> {
    return this.http.get<PossibleValuesDTO<string>[]>(`${isReport ? this.reportingUrl : this.serviceUrl}/getCompanies`);
  }

  override getItemLocationsOfCompanies(companyIds: string[], isReport = false): Observable<ItemLocation[]> {
    return this.http.post<ItemLocationOfCompany[]>(`${isReport ? this.reportingUrl : this.serviceUrl}/getItemLocationsOfCompanies`,
      companyIds).pipe(map((locations) =>
      locations.map(({address, vendorNumber, itemLocationName, itemLocationId}) => ({
        address,
        vendorNumber,
        value: itemLocationId,
        viewValue: itemLocationName,
      })),
    ));
  }

  override getItemLocationsOfVendorNumber(vendorNumber: string): Observable<ItemLocationOfVendorNumber[]> {
    return this.http.get<ItemLocationOfVendorNumber[]>(
      `${this.serviceUrl}/getItemLocationOfVendorNumber?vendorNumber=${vendorNumber}`);
  }

  override getAvailableBookingDays(locationId: string): Observable<BookingDayDTO[]> {
    return this.http.get<BookingDayDTO[]>(
      `${this.serviceUrl}/availableBookingDays?itemLocationId=${locationId}`);
  }

  getPackageTypes(itemLocationId: string, byCollection = false): Observable<PackageTypes[]> {
    return this.fetchPackageTypes(itemLocationId, this.serviceUrl, byCollection);
  }

  bookNewCollection(itemLocationId: string,
                    requestedCollectionDate: string,
                    pickupPackages: Record<PackageTypes, number>,
                    dropOffPackages: Record<PackageTypes, number>,
                    id: string): Observable<boolean> {
    if (id) {
      return this.http.put<boolean>(`${this.serviceUrl}/editCollectionRequest`,
        this.getCollectionRequestDTO(itemLocationId, requestedCollectionDate, dropOffPackages, pickupPackages, id), setResponseMessage({
          message: texts.saveSuccess,
          errorMessage: texts.saveFailed,
        }));
    }
    return this.requestNewCollection(itemLocationId, requestedCollectionDate, pickupPackages, dropOffPackages, this.serviceUrl);
  }

  getCollectionBookingGrid(request: GridRequest): Observable<GridResponse<CollectionBooking>> {
    return this.fetchCollectionBookingGridData(request, this.reportingUrl);
  }

  exportGrid(request: GridRequest, ids: string[], name: string, all: boolean, type: ExportTypes): Observable<void> {
    return this.export(request, ids, this.serviceUrl, name, all, type);
  }

  getItemLocations(): Observable<ItemLocation[]> {
    throw new Error('This method must not be invoked.');
  }

  getLineItemsGrid(request: GridRequest): Observable<GridResponse<CollectionLineDTO>> {
    return this.http.post<GridResponse<CollectionLineDTO>>(`${this.reportingUrl}/getCollectionLines`,
      this.constructCollectionsRequest(request));
  }

  getCallHistory(itemLocationId: string): Observable<ScheduledCallHistoryDTO[]> {
    return this.http.get<ScheduledCallHistoryDTO[]>(`${this.callServiceUrl}/history/${itemLocationId}`);
  }

  getCollectionHistory(itemLocationId: string): Observable<CollectionHistoryDTO[]> {
    return this.http.get<CollectionHistoryDTO[]>(`${this.callServiceUrl}/history/collection/${itemLocationId}`);
  }

  getCollection(collectionId: string): Observable<CollectionRequestDTO> {
    return this.http.get<CollectionRequestDTO>(`${this.serviceUrl}?id=${collectionId}`, setResponseMessage({
      errorMessage: texts.collectionLoadFailed,
    }));
  }

  cancelCollection(collectionId: string): Observable<unknown> {
    return this.http.delete<unknown>(`${this.serviceUrl}/cancelCollectionRequest?id=${collectionId}`, setResponseMessage({
      message: texts.collectionCancelSuccess,
      errorMessage: texts.collectionCancelFailed,
    }));
  }

  voidCollection(request: MarkAsFailedRequest): Observable<unknown> {
    return this.http.patch<unknown>(`${this.serviceUrl}/voidCollectionRequest`, request, setResponseMessage({
      message: texts.voidSuccess,
      errorMessage: texts.voidFailed,
    }));
  }

  closeCollection(request: CollectionCloseRequest): Observable<unknown> {
    return this.http.post<unknown>(`${this.serviceUrl}/closeCollectionRequest`, request, setResponseMessage({
      message: texts.collectionCloseSuccess,
      errorMessage: texts.collectionCloseFailed,
    }));
  }

  markAsFailed(request: MarkAsFailedRequest): Observable<unknown> {
    return this.http.patch<unknown>(`${this.serviceUrl}/failCollectionRequest`, request, setResponseMessage({
      message: texts.markAsFailedSuccess,
      errorMessage: texts.markAsFailedFailed,
    }));
  }

}
